@font-face {
	font-family: 'AGFriquer';
	src: url('../assets/fonts/AGFriquer.otf');
}
* {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  box-sizing: border-box;
}
div, p, span, h1, h2, h3, h4, img {
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  font-family: AGFriquer;
}
html, body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
}
body::before {
  content: '';
  background: url('../assets/images/bg.jpg') center no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
}
.loading {
  width: 90%;
  height: 90vw;
  background: url('../assets/images/main.png') no-repeat center center;
  background-size: contain;
  margin-bottom: 30px;
}
.friday {
  position: absolute;
  left: 50px;
  top: 0;
  width: 140px;
  height: 140px;
  background: url('../assets/images/friday.png') no-repeat center center;
  background-size: 100% 100%;
}
.loading-bar {
  width: 90%;
  height: 15vw;
  background: url('../assets/images/loading-bar.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-style: italic;
  font-size: 26px;
}
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.header {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 40px;
  z-index: 1;
}
.ios-indent-top {
  width: 100%;
  height: 50px;
}
.content {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 100%;
  height: 130px;
  display: flex;
  align-items: start;
  justify-content: space-around;
  z-index: 1;
}
.button-red {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 90px;
  font-size: 18px;
  background: url('../assets/images/button-red.png') no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.button-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 90px;
  font-size: 18px;
  background: url('../assets/images/button-blue.png') no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.button-grey {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 90px;
  font-size: 18px;
  background: url('../assets/images/button-grey.png') no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
.menu-block-image {
  width: 90%;
  flex: 1;
  background: url('../assets/images/main.png') no-repeat center center;
  background-size: contain;
  margin-top: 30px;
}
.menu-footer-block {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.menu-block-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.message-block {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  animation: fadeout 4s;
  background-color: rgba(0, 0, 0, .5);
}
.message {
  width: 85%;
  height: 190px;
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 19px;
  box-sizing: border-box;
  padding: 20px;
  animation: fadeout 4s;
  z-index: 10000;
}
@keyframes fadeout {
  80% { opacity: 1 }
  100% { opacity: 0 }
}
.ready-logo {
  width: 60%;
  height: 60vw;
  background: url('../assets/images/logo.png') no-repeat center center;
  background-size: 100% 100%;
}
.ready-message {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);  
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 24px;
  padding: 30px;
  margin-top: 20px;
}
.ready-attention {
  font-size: 30px;
  padding-bottom: 10px;
}
.ready-buttons {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.ratings-logo {
  width: 100%;
  height: 60vw;
  background: url('../assets/images/logo.png') no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: end;
  justify-content: center;
}
.ratings-header {
  width: 90%;
  height: 13vw;
  background: url('../assets/images/blue-header.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
  padding-top: 3px;
}
.ratings-content {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
}
.ratings-table {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);  
  opacity: .9;
}
.ratings-your {
  margin-top: 20px;
  width: 90%;
}
.ratings-info {
  margin: 10px 0;
  width: 90%;
  font-size: 20px;
  text-align: center;
}
.ratings-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 20px;
}
.ratings-place {
  min-width: 27px;
}
.ratings-name {
  flex: 1;
}
.ratings-score {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ratings-avatar {
  width: 20px;
  height: 20px;
  background: url('../assets/images/avatar.png') no-repeat center center;
  background-size: 100% 100%;
  margin-right: 17px;
}
.ratings-star {
  width: 17px;
  height: 17px;
  background: url('../assets/images/star.png') no-repeat center center;
  background-size: 100% 100%;
  margin-right: 7px;
}
.ratings-hr {
  width: 93%;
  height: 1px;
  background-color: #FFFFFF;
  opacity: .3;
  margin: 0 auto;
}
.result-image {
  position: absolute;
  width: 100%;
  height: 100vw;
  background: url('../assets/images/questions.jpg') no-repeat center center;
  background-size: 100% 100%;
  z-index: -2;
  display: flex;
  align-items: start;
  justify-content: center;
}
.result-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.result-message {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);  
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  padding: 20px 20px 30px 20px;
  text-align: center;
  margin-bottom: 25px;
}
.result-content {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.result-score {
  font-size: 44px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.rules-content {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 30px 0;
}
.rules-message {
  width: 90%;
  height: 100%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);  
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 4.62vw;
  position: relative;
}
.rules-message > div {
  position: absolute;
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  padding: 5px 15px 5px 5px;
  overflow-y: scroll;
}
.rules-message > div > div {
  margin: 8px 0;
}
.rules-message > div::-webkit-scrollbar {
  width: 3px;
}
.rules-message > div::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
}
.ready {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('../assets/images/gradient.png') no-repeat center center;
  background-size: 100% 100%;
}
.ready-block {
  width: 100%;
  position: absolute;
  top: 0;
}
.ready-timer {
  width: 100%;
  height: 15vw;
  background: url('../assets/images/bg-timer.png') no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 80vw;
  font-size: 44px;
  position: absolute;
  z-index: 1;
}
.ready-header {
  width: 100%;
  height: 13vw;
  background: url('../assets/images/blue-header.png') no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  bottom: 0;
  z-index: 1;
  position: absolute;
  padding-top: 3px;
}
.decor-1 {
  position: absolute;
  left: 0;
  top: 50px;
  width: 76px;
  height: 136px;
  background: url('../assets/images/decor-1.png') no-repeat center center;
  background-size: 100% 100%;
}
.decor-2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 125px;
  height: 125px;
  background: url('../assets/images/decor-2.png') no-repeat center center;
  background-size: 100% 100%;
}
.decor-3 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 70px;
  height: 70px;
  background: url('../assets/images/decor-3.png') no-repeat center center;
  background-size: 100% 100%;
}
.decor-4 {
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 90px;
  height: 90px;
  background: url('../assets/images/decor-4.png') no-repeat center center;
  background-size: 100% 100%;
}
.decor-5 {
  position: absolute;
  right: 0;
  top: 300px;
  width: 125px;
  height: 125px;
  background: url('../assets/images/decor-5.png') no-repeat center center;
  background-size: 100% 100%;
}
.gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/images/gradient.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: -1;
}
.round-image {
  width: 100%;
  height: 100vw;
  background: url('../assets/images/questions.jpg') no-repeat center center;
  background-size: 100% 100%;
  z-index: -2;
  display: flex;
  align-items: start;
  justify-content: center;
}
.round-header {
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin-top: -80px;
  z-index: 1000;
}
.round-question-block {
  flex: 1;
  width: 100%;
  z-index: 1;
}
.round-timer {
  position: absolute;
  top: 60px;
  right: 40px;
  width: 100px;
  height: 100px;
  background: url('../assets/images/bg-game-timer.png') no-repeat center center;
  background-size: 100% 100%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.round-categories-block {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}
.question-header {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}
.answers-block {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.btn {
  width: 43vw;
  height: 60px;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  overflow-wrap: break-word;
}
.btn-blue {
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
}
.btn-red {
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #FF000D 0%, #B80006 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);  
}
.btn-green {
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #00B608 0%, #007802 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);  
}
.btn-grey {
  background: linear-gradient(0deg, #8A9695, #8A9695),
 linear-gradient(0deg, #FFFFFF, #FFFFFF);
}
.complete {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  display: flex;
  flex-direction: column;
}
.complete-block {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.complete-header {
  width: 90%;
  height: 13vw;
  background: url('../assets/images/blue-header.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  margin: 100px auto 0;
  padding-top: 3px;
}
.complete-result {
  width: 100%;
  font-size: 28px;
  text-align: center;
  margin-top: 40px;
}
.complete-score {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #00B608 0%, #007802 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  padding: 40px 0;
  font-size: 34px;
  text-align: center;
}
.complete-fail {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #FF000D 0%, #B80006 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  padding: 40px 0;
  font-size: 36px;
  text-align: center;
}
.round-3-answer-block {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.complete-explain {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto 0;
  padding: 20px;
  font-size: 28px;
  text-align: center;
}
.input {
  width: 60%;
  height: 70px;
  resize: none;
  user-select: none;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  font-size: 22px;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
}
textarea:focus,
input:focus {
  outline: none;
}
.video {
  position: absolute;
  top: 0;
  width: 100%;
}
.video-block {
  position: absolute;
  top: 10vh;
  width: 100%;
  min-height: 100vw;
}
.video-gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/images/gradient.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: 0;
}
.top-rules-gradient {
  position: absolute;
  left: 0;
  top: -20vh;
  right: 0;
  bottom: 50vh;
  background: url('../assets/images/gradient.png') no-repeat center center;
  background-size: 100% 100%;
  z-index: 0;
  transform: scaleY(-1);
}
.bottom-rules-gradient {
  position: absolute;
  left: 0;
  top: 20vh;
  right: 0;
  bottom: 0;
  background: url('../assets/images/rules-gradient.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
}
.bottom-round-gradient {
  position: absolute;
  left: 0;
  top: 5vh;
  right: 0;
  bottom: 0;
  background: url('../assets/images/rules-gradient.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
}
.fixed-footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.super-question-content {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0;
}
.super-question-content > input {
  width: 90%;
}
.super-question-info {
  width: 90%;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: .9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto;
  padding: 25px;
  font-size: 20px;
  text-align: center;
}
.super-question-info > span {
  font-size: 28px;
  margin-bottom: 5px;
}
.super-question-descr {
  margin-top: 5vh;
  font-size: 30px;
  text-align: center;
}
.choose-block {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 10vh;
  z-index: 1000;
}
.rules-copyright {
  position: absolute;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 25px;
  font-size: 20px;
  bottom: -15vh;
}
.footer-main {
  padding: 0 5.37vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-main-red {
  width: 25.9vw;
  height: 21.6vw;
  background: radial-gradient(50% 50% at 50% 50%, #FF000D 0%, #B80006 100%), radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%);
  border: 0.3vw solid #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 50%;
}
.btn-main-grey {
  width: 25.9vw;
  height: 21.6vw;
  background: #8A9695;
  border: 0.3vw solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border-radius: 50%;
}
.btn-main-blue {
  width: 25.9vw;
  height: 21.6vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%), radial-gradient(50% 50% at 50% 50%, #FF000D 0%, #B80006 100%);
  border: 0.35vw solid #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 50%;
}
.button-supergame-disable {
  width: 89.25vw;
  height: 21.76vw;
  background: linear-gradient(0deg, #8A9695, #8A9695), radial-gradient(50% 50% at 50% 50%, #FF000D 0%, #B80006 100%), radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%);
  border: 0.35vw solid #FFFFFF;
  border-radius: 5.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;
}
.button-supergame-enable {
  width: 89.25vw;
  height: 21.76vw;
  background: radial-gradient(50% 50% at 50% 50%, #FF000D 0%, #B80006 100%), radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%), #D9D9D9;
  border: 0.35vw solid #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 5.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  text-transform: uppercase;
}
.stream-logo {
  width: 100%;
  height: 60vw;
  background: url('../assets/images/logo.png') no-repeat center center;
  background-size: contain;
}
.stream-round-header {
  margin: 10px auto;
  width: 92.5vw;
  height: 14.3vw;
  background: url('../assets/images/blue-header.png') no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
}
.stream-timer {
  margin: 9.72vw auto 0;
  width: 38.88vw;
  height: 19.59vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #FF000D 0%, #B80006 100%);
  border: 0.41vw solid #FFFFFF;
  box-shadow: inset 0px 5.91549px 5.91549px rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
}
.stream-timer-descr {
  margin: 9.72vw auto 0;
  width: 83.61vw;
  text-align: center;
  font-size: 20px;
}
.stream-back-button {
  position: absolute;
  bottom: 8.7vw;
  left: 5.37vw;
  width: 89.26vw;
  height: 15.74vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%), #8A9695;
  border: 0.35vw solid #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 5.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
}
.stream-screen {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url('../assets/images/clean-bg.jpg') no-repeat;
  background-size: 100% 100%;
}
.stream-screen::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180.4deg, rgba(21, 32, 71, 0) 0.33%, rgba(0, 17, 58, 0.8) 67.87%);
  mix-blend-mode: hard-light;
}
.stream-screen-block {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.stream-questions-header {
  width: 100%;
  padding-top: 20vw;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
}
.stream-questions-count {
  padding-top: 1vw;
  font-size: 27px;
  text-align: center;
  text-transform: uppercase;
}
.stream-questions-timer {
  margin: 7.22vw auto 0;
  width: 30.18vw;
  height: 15.09vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #FF000D 0%, #B80006 100%);
  border: 0.35vw solid #FFFFFF;
  box-shadow: inset 0px 3.96799px 3.96799px rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.stream-btn {
  width: 43vw;
  height: 13.05vw;
  border: 1px solid #FFFFFF;
  border-radius: 13px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  margin-top: 3.7vw;
  cursor: pointer;
  overflow-wrap: break-word;
}
.stream-logo-indent {
  width: 100%;
  height: 24vw;
}
.stream-answers-accepted {
  margin: 14.07vw auto 0;
  width: 89.26vw;
  height: 16.76vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%), #D9D9D9;
  border: 0.35vw solid #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 5.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 24px;
  padding-top: 1vw;
}
.stream-results {
  margin-top: 8.88vw;
  width: 100%;
  text-align: center;
  font-size: 24px;
}
.stream-ratings-header {
  margin: 0 auto;
  width: 87.31vw;
  height: 14.166vw;
  background: url('../assets/images/blue-header.png') no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5.37vw;
  text-transform: uppercase;
}
.stream-place-block {
  margin: 0 auto;
  width: 85vw;
}
.stream-place-headers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
.stream-place-info-block {
  width: 100%;
  margin-top: 4.35vw;
  padding: 0 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stream-place-green {
  width: 37.84vw;
  height: 13.8vw;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #00B608 0%, #007802 100%);
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding-top: 1vw;
}
.stream-place-blue {
  width: 37.84vw;
  height: 13.8vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%);
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding-top: 1vw;
}
.stream-big-star {
  width: 4.44vw;
  height: 5vw;
  background: url('../assets/images/star.png') no-repeat center top;
  background-size: contain;
  margin-right: 1vw;
}
.stream-small-star {
  width: 3.15vw;
  height: 3.15vw;
  background: url('../assets/images/star.png') no-repeat center center;
  background-size: contain;
  margin-right: 1vw;
}
.stream-first-round-top {
  margin: 9.25vw auto 0;
  width: 85vw;
  height: 51.48vw;
  background: #010870;
  opacity: 0.95;
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 5.55vw;
}
.stream-top-10-header {
  text-align: center;
  margin: 4.63vw 0;
  font-size: 6.48vw;
}
.stream-top-block {
  margin: 0 auto;
  width: 88.33vw;
  padding: 2.5vw 0;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%);
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
}
.stream-user-block {
  width: 100%;
  height: 4.44vw;
  padding-left: 5.694vw;
  padding-right: 6.8vw;
  display: flex;
  align-items: center;
  font-size: 3.88vw;
}
.stream-user-avatar {
  width: 3.88vw;
  height: 3.668vw;
  margin-right: 4vw;
  background: url('../assets/images/avatar.png') no-repeat center center;
  background-size: contain;
}
.stream-user-name {
  flex: 1;
}
.stream-user-line {
  margin: 1.2vw auto;
  background-color: #FFFFFF;
  opacity: .5;
  height: 0.185vw;
  width: 89.7%;
}
.stream-top-10-descr {
  width: 100%;
  text-align: center;
  font-size: 4.44vw;
  margin-top: 8.426vw;
}
.stream-leaders {
  width: 100%;
  text-align: center;
  margin-top: 7.77vw;
  margin-bottom: 4.537vw;
  font-size: 6.48vw;
}
.stream-leaders-line {
  margin: 2.5vw auto;
  background-color: #FFFFFF;
  opacity: .5;
  height: 0.185vw;
  width: 89.7%;
}
.stream-top-3-descr {
  width: 100%;
  text-align: center;
  font-size: 5.55vw;
  margin-top: 10vw;
}
.stream-next-button {
  position: absolute;
  bottom: 8.7vw;
  left: 5.37vw;
  width: 89.26vw;
  height: 15.74vw;
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%), #8A9695;
  border: 0.35vw solid #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 5.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
}
.stream-final-round-top {
  margin: 9.25vw auto 0;
  width: 85vw;
  background: #010870;
  opacity: 0.95;
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 5.55vw;
  padding: 6.76vw 0;
}
.stream-final-round-top > span {
  font-size: 8.88vw;
}
.stream-form-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 5vw;
}
.stream-form-block {
  margin: 5.65vw auto 0;
  padding: 2.96vw 5.46vw 7vw 5.46vw;
  width: 81.85vw;
  background: #010870;
  opacity: 0.95;
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
}
.stream-form-label {
  font-size: 4.44vw;
}
.stream-form-input {
  margin-top: 3.33vw;
  margin-bottom: 2vw;
  width: 100%;
  height: 6.85vw;
  background: #010870;
  opacity: 0.95;
  border: 0.35vw solid #FFFFFF;
  border-radius: 2.77vw;
  padding-left: 3vw;
  padding-right: 3vw;
  font-size: 4.44vw;
}
.stream-form-input::placeholder {
  color: #FFFFFF;
  opacity: 0.5;
}
.stream-select-bank {
  position: relative;
  display: flex;
  align-items: center;
}
.stream-form-arrow {
  position: absolute;
  right: 3vw;
  top: 3vw;
  width: 3.1vw;
  height: 1.57vw;
  background-image: url('../assets/images/select-arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.stream-form-bank-descr {
  font-size: 2.96vw;
}
.stream-form-checkbox-block {
  width: 100%;
  margin-top: 3.98vw;
  display: flex;
  align-items: center;
}
.form-stream-checkbox {
  width: 7.96vw;
  height: 7.96vw;
  background: #010870;
  border: 0.35vw solid #FFFFFF;
  border-radius: 1.85vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-stream-checkbox-enable {
  width: 5.37vw;
  height: 5.37vw;
  background: #FFFFFF;
  border-radius: 0.92vw;
}
.form-stream-checkbox-descr {
  flex: 1;
  font-size: 3.3vw;
  padding-left: 2.77vw;
}
.stream-form-list {
  position: absolute;
  left: 0;
  top: 6.85vw;
  width: 100%;
  background: linear-gradient(0deg, #010870, #010870), #FFFFFF;
  border: 0.35vw solid #FFFFFF;
  border-radius: 6vw;
  font-size: 3.51vw;
}
.stream-list-bank {
  height: 8.15vw;
  display: flex;
  align-items: center;
  padding-left: 6.45vw;
}
.stream-list-bank-line {
  width: 100%;
  height: 0.35vw;
  background-color: #FFFFFF;
}
.stream-form-bank-placeholder {
  color: #FFFFFF;
  opacity: .5;
}
.stream-form-button {
  margin: 5.09vw auto 0;
  width: 81.48vw;
  height: 15.74vw;
  border: 0.35vw solid #FFFFFF;
  border-radius: 5.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 6.66vw;
  cursor: pointer;
}
.stream-form-button.disable {
  background: #8A9695;
}
.stream-form-button.enable {
  background: radial-gradient(50.03% 50.03% at 49.9% 49.88%, #010F9F 0%, #010870 100%), #8A9695;
}
.stream-form-cancel-descr {
  margin: 13.24vw auto 0;
  text-align: center;
  width: 71.66vw;
  font-size: 3.33vw;
}
.stream-form-cancel {
  text-align: center;
  margin-top: 6.38vw;
  font-size: 4.44vw;
  text-decoration: underline;
}
.tutorial-block {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, .5);
}
.tutorial-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: start;
  justify-content: space-around;
}
.tutorial-btn-main-space {
  width: 25.9vw;
  height: 21.6vw;
}
.tutorial-message {
  width: 85%;
  height: 190px;
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  background: radial-gradient(54.55% 54.55% at 50% 45.45%, #010F9F 0%, #010870 100%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 19px;
  box-sizing: border-box;
  padding: 20px;
}
.tutorial-bubble {
  width: 55vw;
  height: 26.29vw;
  background-image: url('../assets/images/tutorial.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 130px;
  left: 22.77vw;
}
.notif {
  position: absolute;
  top: 13.37vw;
  left: 5.37vw;
  width: 11.48vw;
  height: 11.48vw;
  background-image: url('../assets/images/notif.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.notif-tutorial {
  position: absolute;
  top: 28vw;
  left: 14vw;
  width: 54.35vw;
  height: 25.19vw;
  background-image: url('../assets/images/notif-tutorial.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: fadeout 6s;
}
.main-norif-checkbox-block {
  width: 67vw;
  margin-top: 3.98vw;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.main-norif-checkbox {
  width: 7.96vw;
  height: 7.96vw;
  background: #010870;
  border: 0.35vw solid #FFFFFF;
  border-radius: 1.85vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-norif-checkbox-enable {
  width: 5.37vw;
  height: 5.37vw;
  background: #FFFFFF;
  border-radius: 0.92vw;
}
.main-norif-descr {
  flex: 1;
  font-size: 4vw;
  padding-left: 2.77vw;
}